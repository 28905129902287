@import './variables';

.input-holder {
  flex-direction: column;
  margin-bottom: 16px;

  .ant-input-affix-wrapper {
    padding: 0;
    border: 0;

    // background-color: #e9ffde !important;
    // border: 2px solid $color-main;
    // border-radius: 10px 10px 10px 10px;
    .ant-input-prefix {
      padding: 20px;
      font-size: 20px;
      margin: 0;
    }
  }

  .ant-form-item-control-input {
    .ant-input {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      // background-color: #e9ffde !important;
      height: 56px;
      padding: 11px 0;
      letter-spacing: 0.15px;
      padding-top: 19px;

      &.input-order-comment {
        background-color: #f9fff6 !important;
        border-bottom: 1px solid #b1b2b0;
        // max-width: 400px;
      }

      @include input-placeholder() {
        font-size: 16px;
        font-weight: normal;
        color: rgba(0, 0, 0, 0.6);
        letter-spacing: 0.15px;
      }
    }

    .ant-input-password {
      // background-color: #e9ffde !important;
      // border: 2px solid $color-main;
      // border-radius: 10px 10px 10px 10px;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding: 0;

      .ant-input-suffix {
        padding-right: 30px;

        &:lang(ar) {
          padding-right: 0;
          padding-left: 20px;
        }
      }

      .ant-input {
        border: 0;
      }
    }
  }

  .ant-form-item-label {
    text-align: left;

    label {
      font-size: 14px;
      color: #212529;
      font-weight: 500;
      height: auto;
    }

    &:lang(ar) {
      text-align: right;
    }
  }
}

.input-control {
  border: 2px solid $color-main !important;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
}

.ant-form-item-label > label::after,
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-form-item-explain,
.ant-form-item-extra {
  // position: absolute;
  // bottom: -24px;
  // white-space: nowrap;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px 10px 0 0;
  border: none;
  padding: 0 20px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 56px;
  // background-color: #e9ffde !important;
  border: 2px solid $color-main;
  line-height: 56px;
  padding: 0 20px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #868d96;
  line-height: 56px !important;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  border-color: #fe5252 !important;
}

//Custom version of floating label using the ant suffix method
.group-floating-label {
  position: relative;

  .ant-form-item-label {
    text-align: left;
    position: absolute;
    top: 16px;
    z-index: 77;
    left: 60px;
    pointer-events: none;
    transition: all ease 0.3s;

    &:lang(ar) {
      left: auto;
      right: 60px;
    }

    label {
      font-size: 16px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.6);
      letter-spacing: 0.15px;
    }
  }

  &.ant-form-item-has-success,
  &.ant-form-item-has-error {
    .ant-form-item-label {
      // top: 4px;
      transform: translateY(-16px);
      transition: all ease 0.3s;

      // background: #e9ffde !important;
      label {
        font-size: 12px;
        color: #00672c;
      }
    }
  }

  // &.ant-form-item-has-success {
  //   .ant-form-item-control-input {
  //     border-bottom: 2px solid #00672C;
  //   }
  // }
  // &.ant-form-item-has-error {
  //   .ant-form-item-control-input {
  //     border-bottom: 2px solid #fe5252;
  //   }
  // }

  &.no-icon {
    .ant-form-item-label {
      left: 20px;

      &:lang(ar) {
        left: auto;
        right: 20px;
      }
    }

    .ant-input {
      text-indent: 20px;
    }

    &.group-floating-label-textarea {
      .ant-form-item-label {
        left: 0;
        padding: 0 20px;
        // background: #e9ffde !important;
        border-radius: 10px 10px 0 0;

        &:lang(ar) {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-slider-track,
.ant-slider:hover .ant-slider-track {
  background-color: #00672c !important;
}

.ant-slider-handle {
  border: 0;
  width: 16px;
  height: 16px;
  background-color: #00672c;
}

.ant-select-item.ant-select-item-option-active:not(
    .ant-select-item-option-disabled
  ) {
  // background-color: #f2ffec;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.48px;
  color: #00672c !important;
}

.ant-select-item.ant-select-item-option {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.48px;
  color: #000;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
    .ant-select-customize-input
  )
  .ant-select-selection-search-input {
  height: 100%;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 56px;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: $color-main !important;
  box-shadow: none !important;
}

.ant-input-number {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;

  input {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 8px;
  }
}
