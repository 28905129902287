.root {
  position: relative;
}

.link {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.56;
  letter-spacing: 0.15px;
  color: #000000 !important;
}

.loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999999999999999999999999;
  // background: url(../../../images/299.gif) center no-repeat #FFF;
}

.breadcrumb {
  background: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding-top: 0;

  >li {
    display: flex;
    align-items: center;
    text-transform: capitalize;

    a {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 1.4px;
      color: #242729;
      text-transform: uppercase;

      &:lang(ar) {
        letter-spacing: normal;
      }
    }

    &.active {
      color: #242729;
      font-weight: normal;
      text-transform: capitalize;
    }

    &:not(:first-of-type) {
      &::before {
        content: "\203A";
        font-size: 29px;
        margin-bottom: 5px;
        font-weight: 400;
        color: #0e1317;
      }
    }
  }
}

.slider-heading {
  margin-bottom: 30px;

  @include mq("tablet") {
    margin-bottom: 30px;
  }

  h3 {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.75px;
    color: $primary;
    line-height: 1;
  }

  @include mq("tablet") {
    margin-bottom: 30px;
  }
}

.slick-slider {
  .slick-dots {
    bottom: -50px;

    li {
      button {
        background-color: #fff;
        border: 2px solid $primary;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        transition: background-color ease 0.3s;

        &::before {
          content: "";
        }
      }

      &:hover,
      &.slick-active {
        button {
          background-color: $primary;
        }
      }
    }
  }

  @include mq("phablet") {

    .product-card,
    .company-card,
    .brand-card {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.call-tel-style {
  overflow: hidden;
  position: relative;

  .tel,
  .text {
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out 0.3s;
  }

  .tel {
    transform: translate(-50%, 40px);

    @include mq("phablet") {
      font-size: 13px;
    }
  }

  &:hover {
    .text {
      transform: translate(-50%, -40px);
    }

    .tel {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
}

.counter-action {
  margin-bottom: 12px;

  .qty {
    display: flex;
    height: 38px;
    border: solid 1px #e0e0e0;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #242729;
    padding: 0;

    input {
      width: 40px;
      background: transparent;
      text-align: center;
      border: 0;
      outline: 0;
      font-weight: normal;
      font-size: 16px;
      // margin-top: -4px;
      pointer-events: none;
      color: #242729;
    }

    span {
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      height: 100%;
      user-select: none;
    }
  }
}

.empty-alert-wrapper {
  width: 100%;
  height: 100%;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  display: none;

  h6 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #707070;
  }

  .add-new-address-btn {
    width: 181px;
    height: 48px;
    border: solid 1px #000000;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
}

.show-empty-alert {
  .empty-alert-wrapper {
    display: flex;

    &~header {
      display: none !important;
    }

    &~div {
      display: none !important;
    }
  }
}

.qty {

  .minus,
  .plus {
    cursor: pointer;

    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.price--after__0 {
  display: none !important;
}

// start Toastbar style

.toastbar {
  visibility: hidden;
  min-width: 300px;
  max-width: 45%;
  background-color: $primary;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  display: flex !important;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.2;
  z-index: 88888;

  >strong {
    font-weight: 500;
    font-size: 16px;
    padding: 0 4px;
    line-height: 1.2;

    &.name {
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include mq("tablet") {
        max-width: 100%;
      }
    }
  }

  &.show {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.typ-new-logo {
  width: 140px;
  max-width: 100%;
  min-width: 140px;
}

// .animate-text-scal {
//   display: inline-block;
//   animation-name: css-animation;
//   animation-duration: 2.5s;
//   animation-iteration-count: infinite;
//   animation-direction: alternate;
//   animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
// }

// @keyframes css-animation {
//   0% {
//     transform: scale(.9);
//   }

//   100% {
//     transform: scale(1.4);
//     font-weight: 500;
//     color: orangered;
//   }
// }

.animate-offers {
  // width: 60px;
  // line-height: 60px * 4;
  display: inline-block;
  padding: 7px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  // margin: 60px;
  font-size: 0.8rem;
  letter-spacing: .4px;
  border: none;
  // padding: 0;
  position: relative;
  // MAKE SURE YOU HAVE AN ACCESSIBE FOCUS STATE
  outline: none;

  color: #00672C;
  // color: #000;
  transition: all .2s ease;
  // ACTIVE
  animation: shockwaveJump 2s ease-out infinite;

  &:hover {
    animation: none;

  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;

    animation: shockwave 2s 1.65s ease-out infinite;

  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;

    animation: shockwave 2s 1.5s ease-out infinite;
  }
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(0.98);
  }

  55% {
    transform: scale(1.02);
  }

  60% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

// @keyframes shockwave {
//   0% {
//     transform: scale(1);
//     box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
//   }

//   95% {
//     box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
//   }

//   100% {
//     transform: scale(2.25);
//   }
// }
@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.3), inset 0 0 1px rgba(255, 255, 255, 0.3);
  }

  95% {
    box-shadow: 0 0 50px rgba(255, 0, 0, 0.0), inset 0 0 30px rgba(255, 0, 0, 0.0);
  }

  100% {
    transform: scale(2.25);
  }
}

.ani-text-shine {
  background: #000 -webkit-gradient(linear,
      left top,
      right top,
      from(#000),
      to(#000),
      color-stop(0.2, rgb(255, 0, 0))) 0 0 no-repeat;
  background-size: 150px;
  color: rgba(0, 0, 0, .4);
  background-clip: text;
  animation-name: shine;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}

@keyframes shine {
  10% {
    background-position: -200px;
  }

  20% {
    background-position: top left;
  }

  90% {
    background-position: top right;
  }

  100% {
    background-position: 200px;
  }
}