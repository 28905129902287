@import "variables";
@import "Placeholder";
@import "mixins";
@import "reset";
@import "inputs";
@import "buttons";
@import "shared";

body {
    >iframe {
        display: none !important;
    }
}


// for categories menu
// div[class*='ant-dropdown-menu-submenu ant-dropdown-menu-submenu-popup ant-dropdown-menu ant-dropdown-menu-light'] {
//     // top: 115px !important;
//     top: 121px !important;

//     @media only screen and (max-width: 992px) {
//         // top: 90px !important;
//         top: 96px !important;
//     }
// }

.ant-drawer {
    .ant-drawer-content-wrapper {
        max-width: 100% !important;

        .ant-menu {
            border: none;


        }

    }

    &.ant-drawer-left {
        .ant-drawer-content-wrapper-hidden {
            transform: translateX(-100%);
        }
    }

    &.ant-drawer-right {
        .ant-drawer-content-wrapper-hidden {
            transform: translateX(100%);
        }

    }
}

.ant-dropdown {
    .ant-dropdown-menu-submenu-expand-icon {
        &:lang(ar) {
            right: auto;
            left: 8px;
        }
    }
}