@import '../../scss/mixins';

.product-page-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .product-content {
    .ant-breadcrumb {
      margin-top: 30px;
      margin-bottom: 40px;
      cursor: pointer;
    }

    .ant-breadcrumb>span:last-child {
      cursor: auto;
    }

    .discount-section-wrapper {
      border-top: solid 1px #b8b8b8;

      .section-title {
        font-size: 20px;
        text-transform: capitalize;
        margin-bottom: 60px;
      }

      @include mq('tablet') {
        >.container {
          padding: 0;
        }
      }
    }
  }

  .product-info-content {
    display: flex;

    // margin-bottom: 100px;
    @include mq('ipad') {
      flex-direction: column;
    }
  }

  .product-info-wrapper {
    .product-img-holder {
      width: 25%;
      max-width: 360px;
      position: relative;
      margin-right: 30px;
      border: solid 1px #d6d6d6;
      border-radius: 10px;
      height: 281px;

      // box-shadow: 0 10px 20px 0 #ebf2f9;
      &:lang(ar) {
        margin-right: 0;
        margin-left: 30px;
      }

      .ant-image {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        // padding: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: contain;
        // object-fit: cover;
        mix-blend-mode: multiply;

        @include mq('ipad') {
          object-fit: contain;
        }
      }

      @include mq('ipad') {
        width: 100%;
        max-width: auto;
        height: 40vh;
        margin: 0 !important;
        margin-bottom: 20px !important;
      }
    }

    .product-details-holder {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .brand-holder {
        display: flex;
        align-items: center;

        .brand-txt {
          font-size: 12px;
          font-weight: 500;
          color: #707070;
          margin-right: 4px;

          &:lang(ar) {
            margin-left: 4px;
            margin-right: 0;
          }
        }

        .brand-title {
          font-size: 12px;
          font-weight: 600;
          color: #21b485;
        }
      }

      .product-type {
        padding: 0;
      }

      .product-name-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        h4 {
          font-size: 22px;
          line-height: 1.6;
          color: #000000;
          font-weight: bold;
          width: 65%;

          @include mq('tablet') {
            width: 95%;
            text-align: center;
            margin-bottom: 20px;
          }
        }

        @include mq('tablet') {
          flex-direction: column;
        }
      }

      .price-holder {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .price-after {
          font-size: 21px;
          font-weight: 600;
          color: #000;
          margin-right: 70px;
          display: flex;
          align-items: center;

          &:lang(ar) {
            margin-right: 0;
            margin-left: 70px;
          }

          @include mq('tablet') {
            margin-right: 20px;

            &:lang(ar) {
              margin-right: 0;
              margin-left: 20px;
            }
          }
        }

        .price-before {
          font-size: 15px;
          color: #9b9b9b;
          font-weight: normal;
          text-decoration: line-through;
          margin-left: 4px;
          margin-right: 20px;
          white-space: nowrap;

          &:lang(ar) {
            margin-right: 4px;
            margin-left: 20px;
          }
        }
      }

      .product-desc {
        margin-top: 15px;
        margin-bottom: 30px;

        h5 {
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          line-height: 1.19;
        }

        p {
          font-size: 12px;
          line-height: 1.6;
          color: #000;
          margin-top: 6px;
          width: 75%;

          @include mq('tablet') {
            width: 100%;
          }
        }
      }

      .card-count-btns .btns-wrapper {
        flex-direction: row;
        width: 100%;
      }
    }
  }

  .product-details-holder {
    // padding: 0 40px;
    // box-shadow: 0 10px 20px 0 #ebf2f9;
    background-color: #ffffff;

    @include mq('phablet') {
      padding: 20px;
      flex-direction: column;
    }
  }

  .product-cart-wrapper {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // width: 100%;

    .add-btn {
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 17.5px !important;
      border: solid 1px rgba(0, 0, 0, 0);
    }

    .card-count-btns {
      gap: 1rem;
      width: 100%;

      .card-control {
        width: 100%;

        // margin-left: 20px;
        &:lang(ar) {
          margin-left: 0;
          margin-right: 20px;
        }
      }
    }

    @include mq('phablet') {
      flex-direction: column;

      .card-control {
        margin-bottom: 0;
      }
    }
  }

  .card-count-holder {
    display: flex;
    align-items: center;
    // flex-direction: row;
    position: relative;

    .product-count {
      width: 70px;
      text-align: center;
      user-select: none;
      font-size: 14px;
      font-weight: 500;
      color: #000;
    }

    svg {
      font-size: 22px;
      fill: #fff;
      cursor: pointer;
    }

    button {
      border: 0;
      padding: 0;
      width: 33px;
      height: 33px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00672C;
      border-radius: 50% !important;

      .ant-btn-loading-icon {
        position: absolute;
        right: 0;
        width: 30px;
        background: #fff;
      }

      &:disabled {
        // svg{
        //   fill: gray;
        // }
      }
    }
  }

  .add-fav-dlt-holder {
    .add-btn {
      min-width: 220px;
    }

    @include mq('phablet') {
      width: 100%;
      text-align: center;

      .add-btn {
        min-width: auto;
        width: 90%;
      }
    }
  }

  .add-fav-dlt-holder {
    .dlt-btn {
      margin-right: 40px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 40px;
      }

      svg {
        fill: #ff0000;
      }
    }

    svg {
      // fill: #ff0000;
      font-size: 28px;
      cursor: pointer;
    }
  }

  .product-discount {
    position: absolute;
    top: 11px;
    left: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    height: 23px;
    border-radius: 4px;
    border: solid 1px #cc0033;
    background-color: #fff;
    font-size: 9px;
    font-weight: normal;
    text-align: center;
    color: #cc0033;
    padding: 4px 10px;

    &:lang(ar) {
      right: 11px;
      left: auto;
    }
  }
}

.product-details-sec-col {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.11);
  border: solid 1px #e4e4e4;
  padding: 16px 13px;
  height: fit-content;
  min-width: 300px;
  max-width: 300px;

  .taxed-span {
    font-size: 12px;
    font-weight: 600;
    color: #9b9b9b;
    margin-top: 3px;
  }

  .product-cart-wrapper {
    .add-btn {
      width: 100% !important;
    }
  }

  .product-delivery-feature {
    margin-top: 19px;

    .feature-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      img {
        margin-right: 10px;

        &:lang(ar) {
          margin-left: 10px;
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        font-size: 11px;
        font-weight: normal;
        color: #9b9b9b;
      }
    }
  }
}

.product-details-first-col {
  .in-stock-fav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 20px;

    .fav-name-holder {
      display: flex;
      align-items: center;
      cursor: pointer;

      >p {
        font-size: 12px;
        font-weight: 600;
        margin-left: 4px;
        margin-right: 4px;
      }
    }

    .ant-badge-status-text {
      font-size: 12px;
      font-weight: 600;
      color: #8d8d8d;
    }
  }
}