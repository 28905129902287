.discount-section-wrapper{
    background-color: #fff;
    padding: 40px 0;
    margin-top: 60px;
    .section-title{
        text-align: left;
        &:lang(ar){ 
            text-align: right;
        }
    }
}
.discount-section-content{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 225px)); 
    gap : 28px;  
    min-height: 200px;
    .product-card-wrapper{
        max-width: 320px; 
    }
} 