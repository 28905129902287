/************LINKS ********************/
@mixin link ($color) {
    color: $color;
    display: inline-block;
    transition: 0.3s;
    font-size: 0.9375rem; 
    text-decoration: underline;
    text-transform: capitalize;
}

/************BTNS ********************/
@mixin btn ($background, $color, $border) {
    background-color: $background;
    border: 1px solid $border;
    color: $color;
    display: inline-flex;
    align-items: center;
    justify-content: center; 
    padding: 0 23px;
    font-size: 1rem;
    font-weight: normal;
    border-radius: 3px;
    cursor: pointer;
    text-transform: capitalize;
    @include mq("phablet") {
        height: 30px;
        padding: 0 20px;
    }
}
 
/************MEDIA QUERY ********************/
$breakpoints: (
    "xs-phone":     360px,
    "phone":        400px,
    "phone-wide":   480px,
    "phablet":      576px,
    "tablet-small": 640px,
    "tablet":       768px,
    "ipad":         992px,
    "tablet-wide":  1024px,
    "desktop":      1248px,
    "desktop-wide": 1440px
);

@mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);

        @if $type==max {
            $width: $width - 1px;
        }

        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

/*****************prfix*************/
@mixin prefixer ($property, $val) {
    #{$property}: $val;
    -webkit-#{$property}: $val;
    -moz-#{$property}: $val;
    -ms-#{$property}: $val;
    -o-#{$property}: $val;
}

/*****************input placeholder*************/
@mixin input-placeholder {
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}