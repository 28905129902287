.footer-wrapper {
  background-color: #fff;
  padding: 40px 0;

  .footer-content {
    // width: 80%;
    // margin: auto;
    // .links-wrapper {
    //   width: 100%;
    //   display: grid;
    //   grid-template-columns: 0.5fr 2.5fr 1.5fr 1.5fr 1.2fr;
    //   // grid-template-columns: 1fr 2.5fr 1.5fr 1.5fr;
    //   gap: 33px;

    //   @media only screen and (max-width: 992px) {
    //     display: flex;
    //     flex-wrap: wrap;
    //   }
    // }

    // For 3 columns
    .links-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      // grid-template-columns: 1fr 2.5fr 1.5fr 1.5fr;
      gap: 33px;

      @media only screen and (max-width: 992px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .links-group {
      display: flex;
      flex-direction: column;

      &:first-of-type {
        text-align: right;
        margin-right: 30px;

        &:lang(ar) {
          text-align: left;
          margin-right: 0;
          margin-left: 30px;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: bold;
        color: #00672c;
        line-height: 1.19;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      p {
        font-size: 14px;
        line-height: 1.5;
        color: #232323;
        // max-width: 350px;
        max-width: 460px;

        &:lang(ar) {
          line-height: 1.7;
          letter-spacing: 1px;
        }
      }

      ul {
        > li {
          margin-bottom: 10px;

          a {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.83;
            color: #232323;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .all-rights-wrraper {
      text-align: center;
      margin-top: 1.5rem;

      @media only screen and (max-width: 992px) {
        padding-bottom: 30px;
      }
    }
  }
}

.follow-wrapper {
  margin-top: 26px;

  .follow-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.56;
    color: #232323;
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    align-items: center;

    li {
      margin-bottom: 0 !important;
      margin-right: 10px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  // @include mq("ipad"){
  //     display: flex;
  // }
}

.copy-r {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 300;
  color: #8e8e8e;
  margin-top: 13px;
  text-align: center;
  display: block;

  &:lang(ar) {
    direction: ltr;
  }
}

.MANSOUR-logo {
  img {
    width: 78px;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .footer-wrapper {
    .logos-web-holder {
      flex-direction: row !important;

      .MANSOUR-logo {
        img {
          // margin-top: 0;
          height: 78px;
          margin: 0 16px;
        }
      }
    }
  }

  .follow-wrapper {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0 !important;
      margin-right: 20px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 20px;
      }
    }
  }

  .footer-wrapper .footer-content .links-group:last-of-type ul {
    display: flex;

    > li {
      margin-right: 10px;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
}
