@import '../base.styles.scss';

.app-wrapper {
  min-height: 100vh;
}

.ml-20 {
  margin-left: 20px;

  &:lang(ar) {
    margin-left: 0;
    margin-right: 20px;
  }
}

.section-title {
  font-size: 21px;
  font-weight: 600;
  color: #000000;
  line-height: 1.46;
  margin-bottom: 30px;
  // text-transform: uppercase;
  // text-align: center;
}

.bg-wrapper {
  background-color: #fff;
}

.ant-breadcrumb {
  .ant-breadcrumb-link {
    font-size: 15px;
    font-weight: normal;

    a {
      font-weight: 600;
      color: #00672C;
      text-transform: uppercase;
    }
  }
}

.pluse-animation {
  transform-origin: 0 0;
  animation: leaves 0.5s ease-in-out infinite alternate;
}

@keyframes leaves {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  &:lang(ar) {
    left: 16px;
    right: auto;
  }
}

.no-products-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 40px 0;

  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    // margin-bottom: 30px;
    text-transform: capitalize;
  }

  img {
    width: 300px;
    mix-blend-mode: multiply;
  }
}

.ant-modal-close {
  &:lang(ar) {
    left: 0;
    right: auto;
  }
}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  &:lang(ar) {
    margin-right: 8px;
    margin-left: 0;
  }
}

.address-modal {
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal {
    width: 60% !important;

    @include mq('tablet') {
      width: 90% !important;
    }
  }
}

.discount-section-content {
  @include mq('ipad') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  // @include mq("tablet") {
  //   grid-template-columns: 1fr;
  //   .product-card-wrapper {
  //     max-width: initial;
  //   }
  // }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
    gap: 30px 16px;

    .product-card-wrapper {
      padding-top: 30px;
    }

    .product-card-wrapper .product-img {
      width: auto;
      height: 95px;
      margin-bottom: 0;
    }

    .product-card-wrapper .product-name {
      font-size: 14px;
    }

    .product-card-wrapper .price-holder p {
      font-size: 14px;
    }

    .product-card-wrapper .card-count-holder {
      padding: 8px;
      padding-top: 0;
    }

    // .product-card-wrapper .product-discount{
    //     width: 50px;
    //     height: 50px;
    //     font-size: 13px;
    // }
    .card-count-btns .btns-wrapper .product-count {
      width: 35px;
    }
  }

  @media only screen and (max-width: 370px) {
    grid-template-columns: 1fr;
    gap: 30px 20px;

    .product-card-wrapper .product-img {
      width: auto;
    }
  }
}

.pay-modal {
  .ant-modal-close-x {
    color: #ff0000;
    font-weight: bold;
    font-size: 23px;
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      font-size: 16px;
      font-weight: normal;
      color: #000000;

      .cost-info {
        color: #00672C;
      }
    }
  }
}

.ant-spin-nested-loading {
  // min-height: 200px;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: initial;
  // min-height: 200px;
}

.mr-20 {
  margin-right: 20px;

  &:lang(ar) {
    margin-right: 0;
    margin-left: 20px;
  }
}

.order-content-wrapper .order-main {
  @include mq('tablet') {
    max-width: initial;
    padding: 30px 16px;

    .order-placed-btns {
      display: flex;

      .ant-btn-primary {
        padding: 10px;
      }
    }
  }
}

.alt-modal {
  .ant-modal-header {
    border-radius: 10px 10px 0 0;

    .ant-modal-title {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.15px;
      color: #000;
    }
  }

  .ant-modal {
    width: 70% !important;

    @media only screen and (max-width: 992px) {
      width: 100% !important;

      .product-card-wrapper .product-img {
        width: auto;
      }
    }
  }
}

.home-wrapper {
  .discount-section-wrapper {
    @include mq('tablet') {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.ant-modal-confirm .ant-modal-confirm-btns {
  &:lang(ar) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  &:lang(ar) {
    // margin-right: 8px;
    // margin-left: 0;
  }
}

.ant-picker-input>input {
  @include input-placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }
}

.pagination-wrapper {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.btn-back-holder {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .anticon {
    margin-left: 0;
    margin-right: 8px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.primary-btn {
  border: 0 !important;
  min-width: 135px;
  width: 100%;
  border-radius: 18px !important;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
}

.primary-btn-light {
  background-color: #efefef !important;
  // font-size: 14px;
  // font-weight: normal;
  color: #00672C !important;
  border: 0 !important;
  min-width: 135px;
  width: 100%;
  border-radius: 18px !important;
  height: 40px;
  font-size: 12px;
  font-weight: 600;

  a {
    color: #00672C !important;
  }
}

.input-select-holder {
  margin-bottom: 16px;
  flex-direction: column;

  .ant-form-item-label {
    text-align: left;

    &:lang(ar) {
      text-align: right;
    }
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;

    .ant-select-selection-item {
      line-height: 38px;
    }
  }

  .ant-form-item-label>label {
    font-size: 14px;
    color: #212529;
    font-weight: 500;
  }

  .ant-select-arrow {
    color: #00672C;
  }
}

.upload-wrapper {
  flex-direction: column;
  margin-top: 20px;

  .ant-form-item-label {
    text-align: left;
    font-size: 14px;
    color: #212529;
    font-weight: 500;

    &:lang(ar) {
      text-align: right;
    }
  }

  .ant-upload.ant-upload-drag {
    background-color: #fff;
    border: dashed 1px #00672C;
    border-radius: 5px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 16px;
    font-weight: normal;
    text-align: center;
    color: #00672C;
  }

  .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 12px;
    font-weight: normal;
    text-align: center;
    color: #00672C;
  }
}

.anticon.anticon-phone {
  transform: rotate(90deg);
}

.suumer-hero-wrapper {
  margin-top: 16px;
  margin-bottom: 40px;

  .suumer-hero-img {
    width: 100%;
    position: relative;

    img {
      width: 100%;
    }

    @include mq('tablet') {
      text-align: center;
    }
  }

  .ant-btn.ant-btn-primary {
    position: absolute;
    bottom: 5%;
    right: 50%;
    transform: translateX(50%);

    // &:lang(ar) {
    //   right: auto;
    //   left: 5%;
    // }
    @include mq('tablet') {
      position: static;
      margin-top: 10px;
      transform: translateX(0);
    }
  }
}

.deal-recommend-wrapper {
  // width: 75%;
  margin: auto;
  margin-bottom: 70px;
  margin-top: 30px;

  .section-title {
    margin-bottom: 0;

    @include mq('tablet') {
      font-size: 19px;
    }
  }

  .slider-section-content {
    margin-top: 0;
  }

  @include mq('tablet') {
    margin-bottom: 0;
    width: 90%;
  }
}

.branch-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-close-x {
    color: #f81515;
  }

  .input-holder .ant-form-item-label label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.48px;
    color: #000;
    margin-bottom: 20px;
  }

  .ant-select-arrow {
    margin-right: 8px;
  }

  .ant-select-arrow .anticon {
    color: #000;
  }

  .anticon.anticon-search {
    font-size: 20px;
    color: #00672C;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 10px;
  }
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-bottom: 2px solid #fe5252 !important;
}

.pay-modal {
  .ant-modal-body {
    padding-top: 40px;
  }

  .ant-modal-footer {
    padding-top: 16px;
    padding-bottom: 16px;

    button {
      width: 100px;
      height: 40px;
      font-size: 16px;
    }
  }
}

.select-branch-form {
  .ant-form-item-row {
    display: block;
  }

  .ant-form-item-label label {
    margin-bottom: 10px !important;
  }

  span.ant-radio+* {
    font-size: 17px;
  }
}

.loading-wrapper {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  img {
    width: 400px;
  }

  >.ant-spin {
    transform: scale(2.5);
  }
}

.ant-result-subtitle {
  font-size: 18px;
}

.ant-pagination-options-size-changer.ant-select {
  display: none !important;
}

.text-right {
  text-align: right;

  &:lang(ar) {
    text-align: left;
  }
}

.ant-radio-wrapper.ant-radio-wrapper-rtl {
  letter-spacing: 0.13px;
}