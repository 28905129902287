@import "../../scss/slider.scss";

.home-hero-slider{
    // height: 40vh;
    height: 70vh;
    // padding-top: 10px;
    .container, .home-hero-slider-content{
        height: 100%;
    }
    .home-hero-slider-content{
        display: flex;
        justify-content: space-between;
        .main-slider-holder{
            width: 100%;
            // margin-right: 16px;  
            // &:lang(ar) {
            //     margin-right: 0;
            //     margin-left: 16px;
            // }
        }
        .two-slider-holder{
            display: flex;
            flex-direction: column;
            justify-content: space-between; 
            width: 49%;
            height: 100%;  
            > .slick-slider{
                height: 48%;
            }
        }

        .slick-slider .slick-dots{ 
            bottom: 10px;
        }
        .slick-slider .slick-dots li {
            width: 30px;
            height: 3px;
            padding: 0;
            button{
                padding: 0;
                border-radius: 3px;
                width: 30px;
                height: 3px;
                border : 0; 
                opacity: 0.4;
            }
            &.slick-active{
                button{ 
                    opacity: 1; 
                    background-color : #ffffff; 
                }
            }
        }
        .slick-prev, .slick-next{
            width: 30px;
            height: 30px; 
        }
        .slick-prev:before, .slick-next:before{
            font-size: 16px;
        }
        @media only screen and (max-width: 992px) {
            flex-direction: column;
            > div{
                width: 100% !important;
                margin-bottom: 16px;
                &.two-slider-holder{
                    // flex-direction: row; 
                    height: auto;
                    margin-bottom: 0;
                    > .slick-slider{
                        // height: 50% !important;
                        height: 120px;
                    }
                }
            }
        }
    }
    .slick-slider,
    .slick-list,
    .slick-track{
        height: 100%;
        // border-radius: 10px;
    }
    .slide-holder{
        position: relative;
        height: 100%;
        // border-radius: 10px;
       
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            // border-radius: 10px;
        }
        .content{
            position: absolute;
            left : 16%;
            top : 50%;
            transform : translateY(-50%);
            
            h3{
                font-size: 48px;
                color: #ffffff;
                font-weight: 800;
                line-height: 1.2;
                margin-bottom: 10px;
            }
            h4{
                font-size: 38px;
                font-weight: normal;
                color: #ffffff;
                line-height: 0.94;
            }
            @media only screen and (max-width: 992px) {
                h3{
                    font-size: 30px;
                }
                h4{
                    font-size: 26px;
                }
            }
        }
    }
    .slick-slide{
        > div{
            height: 100%; 
            
        }
    }
}

@media only screen and (max-width: 992px) {
    .home-wrapper{
        .home-hero-slider .slide-holder img{
            object-fit: cover;
        }
        .trending-brand-wrapper{
            height : auto; 
            flex-direction: column;
            .trending-hero img{
                display: none;
            }
        }
        .trending-brand-wrapper .trending-bg-left .content-title{
            padding-bottom: 50px;
        }
        .trending-brand-wrapper .trending-brand-content{
            margin: 0 !important;
            // grid-template-columns: 1fr 1fr;
            // display: flex;
            // overflow: auto;
            // width: 100vw;
            // .brand-holder{
            //     min-width: 125px;
            // }
            &::-webkit-scrollbar {
                display: none;
              }
            
              /* Track */
              &::-webkit-scrollbar-track {
                display: none;
              }
            
              /* Handle */
              &::-webkit-scrollbar-thumb {
                display: none;
              }
        }
        .home-hero-slider{
            height: auto;
            margin-top: 16px;
        }
        // .home-hero-slider .home-hero-slider-content .two-slider-holder{
        //     flex-direction: row; 
        // }
        .home-hero-slider .home-hero-slider-content .two-slider-holder > .slick-slider{
            margin-bottom: 16px;
        }
        .home-hero-slider .home-hero-slider-content .main-slider-holder{
            height: 220px;
        }
    }
}