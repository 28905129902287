.res-bottom-nav {
  height: 60px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  z-index: 40;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  z-index: 55;
  display: none;

  >a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    color: #000;
    text-align: center;
    font-size: 13px;

    .anticon {
      margin-bottom: 5px;
      font-size: 20px;
    }

    &.active {
      .anticon-shopping-cart {
        color: #00672C,
      }
    }
  }

  @media only screen and (max-width: 992px) {
    display: flex;

    .ant-badge-rtl .ant-badge-count,
    .ant-badge-rtl .ant-badge-dot,
    .ant-badge-rtl.ant-badge .ant-scroll-number-custom-component,
    .ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
      left: 20px;
    }
  }
}