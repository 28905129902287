.about-page-wrapper {
  background-color: #fff;

  .about-hero {
    height: 380px;
    background-image: url("https://metro-markets.com/plugins/user/images/aboutImage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    &:lang(ar) {
      // background-image: url('../../assets/img/Recipie-ar.jpeg');
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 44px;
      font-weight: 600;
      line-height: 1.55;
    }

    @media only screen and (max-width: 992px) {
      height: 180px;

      h1 {
        font-size: 34px;
      }
    }
  }

  .ant-breadcrumb {
    margin-top: 45px;
    margin-bottom: 30px;
  }

  .about-content {
    width: 85%;
    margin: auto;

    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .contact-grid-wrapper {
    margin-bottom: 100px;

    ul.contact-info-list {
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        .key {
          display: flex;
          align-items: center;
          gap: 7px;

          font-weight: 500;
          font-size: 18px;
          text-transform: capitalize;
          color: #777;



          // color: #fff;
          svg {
            font-size: 24px;
          }
        }

        .val {
          display: inline-block;
          padding: 5px 0;
          font-weight: 600;
          font-size: 18px;
          color: #444;
          color: #00672C;
          line-height: 1.4;
        }

        &.social-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: hsla(146, 100%, 20%, 0.1);
          padding: 5px 10px;

          img {
            width: 40px;
          }
        }
      }
    }

    .auth-form-content {
      width: 600px;
      max-width: 100%;
      margin: auto;

      form {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 20px;

        // @media only screen and (max-width: 992px) {
        //   grid-template-columns: 1fr;
        // }
      }
    }
  }
}

.form-group-captcha {
  position: relative;
  // top: -80px;

  @media only screen and (max-width: 992px) {
    position: static;
    top: 0;
  }

  #reload_href {
    color: #00672C !important;
    margin-top: 16px;
    display: block;
  }

  .Captcha-input {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .Captcha-btn-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #00672C;
      border-color: #00672C;
      border-radius: 10px;
      height: 56px;
      font-size: 18px;
      font-weight: 600;
      width: 150px;
      cursor: pointer;
      margin-left: 20px;

      &:lang(ar) {
        margin-right: 20px;
        margin-left: 0;
      }
    }

    input {
      border-radius: 10px 10px 0 0;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      background-color: #e9ffde !important;
      border: 0;
      height: 56px;
      padding: 11px 0;
      letter-spacing: 0.15px;
      padding: 19px;
      width: 100%;
    }
  }
}