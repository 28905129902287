.single-order-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .ant-skeleton {
    display: flex;
    align-items: center;
    border-radius: 10px;
    // border: solid 1px #dbdbdb;
    // margin-bottom: 20px;
    padding: 25px;
  }
}

.order-comment {
  > form {
    max-width: 400px;

    .ant-form-item-has-success {
      .ant-input.input-order-comment {
        border-bottom: 0;
      }
    }
  }
}

.order-comment-delivered {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    letter-spacing: -0.42px;
    color: #000;

    &:lang(ar) {
      letter-spacing: 1px;
    }
  }

  img {
    margin-right: 13px;
    width: 30px;
    height: auto;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 13px;
    }
  }
}

.single-order-content {
  width: 85%;
  margin: auto;
  padding: 1rem 0;

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 2rem;
  }

  .order-list-items {
    padding: 1rem;
    border-radius: 0.3rem;
  }

  .single-order-content-items {
    background-color: #fff;
    margin-bottom: 1rem;

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  .single-order-price-info {
    .single-order-price-info-top {
      // background-color: #e9ffde;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      padding: 1rem;
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      border-top: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      // border: 1px solid #e9ffde;
    }

    .single-order-info-footer {
      background-color: #fff;
      padding: 1.5rem 1rem;
      border-left: 1px solid #dbdbdb;
      border-right: 1px solid #dbdbdb;
      border-bottom: 1px solid #dbdbdb;
      border-bottom-left-radius: 0.7rem;
      border-bottom-right-radius: 0.7rem;

      .single-order-info-footer-container {
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: 1fr 2fr;

        &:last-child {
          margin-bottom: 0;
        }

        .single-order-info-item-title {
          font-weight: 700;
          margin-right: 2rem;
        }

        .single-order-info-item-subtitle {
          font-size: 12px;
          line-height: 1.4;
        }
      }
    }

    .single-order-info-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .single-order-info-item {
        font-weight: 500;

        &__bold {
          font-weight: 700;
        }

        &__green {
          color: #00672c;
          font-weight: 600;
          min-width: 61px;
          text-align: left;

          &:lang(ar) {
            text-align: right;
          }
        }
      }

      .single-order-vat {
        font-size: 12px;
        color: #878787;
        font-weight: 500;
      }
    }
  }
}

.single-order-page-bg {
  background-color: #fff;
}

.item-holder {
  display: flex;
  // border-bottom: solid 1px #e5e5e5;
  margin-bottom: 20px;

  // padding-bottom: 20px;
  .product-img {
    width: 60px;
    height: 60px;
    margin-right: 60px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 60px;
    }

    .ant-image {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .product-details-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .product-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    color: #000000;
  }

  .product-count {
    font-size: 14px;
    font-weight: 600;
  }

  .price-holder {
    text-align: center;
    display: flex;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin: 0 4px;
    }

    .price-after {
      // span {
      //   font-size: 10px;
      //   font-weight: normal;
      //   color: #ababab;
      // }
    }

    .price-before {
      font-size: 14px;
      font-weight: 600;
      color: #9b9b9b;
      text-decoration: line-through;
    }
  }
}

.single-order-breadcrumb {
  width: 85%;
  margin: auto;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders-details-wrapper {
  justify-content: start !important;

  .product-info {
    margin-bottom: 0.5rem;
  }
}

.orders-holder {
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  margin-bottom: 0.5rem;
}

.desc {
  font-size: 16px;
  color: #333;
  padding: 1rem 0;
}

.attchs-list-items {
  display: flex;
  // max-width: 300px;
  overflow: auto;
  gap: 15px;

  .attch-holder {
  }
}

.order-input-label {
  // margin-bottom: 2rem !important;
}

.ant-btn-background-ghost.ant-btn-danger.order-cancel-btn {
  background-color: #fff6f6 !important;
  border-radius: 10px !important;
  border: none;
  font-size: 14px;
  font-weight: 700;
  color: #f82727;
  width: 118px;
  height: 44px;
  box-shadow: none;

  &.reorder-btn {
    background-color: #e9ffde !important;
    color: #00672c;
  }
}
