.order-alt-wrapper {
  .order-alt-header {
    margin-bottom: 20px;

    h4 {
      font-size: 14px;
      letter-spacing: -0.42px;
      color: #000;
      font-weight: 500;
      line-height: 1.42;

      &:lang(ar) {
        letter-spacing: 1px;
      }
    }

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.42;
      letter-spacing: -0.36px;
      color: #757373;
    }
  }

  .order-alt-list {
    >.order-alt-holder {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: solid 1px #b8b8b8;

      &:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  .order-alt-holder {
    .order-alt-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .dlt-icon-holder {
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;

        // display: none;
        img {
          margin: 0 10px;
        }
      }

      .order-alt-details {
        display: flex;
        align-items: center;

        .product-img {
          width: 60px;
          height: 60px;
          margin-right: 20px;

          &:lang(ar) {
            margin-right: 0;
            margin-left: 20px;
          }

          .ant-image {
            width: 100%;
            height: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .order-alt-name-price {
          display: flex;
          flex-direction: column;

          .order-alt--name {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 0.15px;
            color: #00672C;
            margin-bottom: 3px;

            .state-can-holder {
              color: #f82727;
              font-size: 15px;
            }
          }

          .order-alt--price {
            font-size: 14px;
            font-weight: 500;
            line-height: 2.29;
            letter-spacing: -0.42px;
            color: #000;

            &:lang(ar) {
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }

  .order-alt-product-list {
    width: 100%;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-radio-group {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 130px));
      gap: 20px;
    }

    .ant-radio-wrapper {
      margin: 0;

      .ant-radio {
        // display: none;
        width: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;

        &+span {
          padding: 0;
        }

        &.ant-radio-checked {
          &+span {
            .radio-item-card {
              border: solid 1px #00672C;
              background-color: #e9ffde;

              .select-al-btn {
                background-color: #00672C;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .radio-item-card {
      border-radius: 9px;
      border: solid 1px #00672C;
      background-color: #fff;
      padding: 10px 15.8px 10px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .product-img {
        width: 60px;
        height: 60px;

        .ant-image {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .order-alt-name-price {
        display: flex;
        flex-direction: column;

        .order-alt--name {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: #000;
          margin: 10px 0;
          text-align: center;
        }

        .order-alt--price {
          font-size: 12px;
          font-weight: 600;
          color: #00672C;
          margin-bottom: 10px;
          text-align: center;
        }
      }

      .select-al-btn {
        width: 88px;
        height: 33px;
        margin: 11px 0.2px 0 0;
        padding: 8px 26px;
        border-radius: 4px;
        border: solid 1px #00672C;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.36px;
        text-align: center;
        color: #00672C;
      }
    }
  }
}