%btn-style {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  min-height: 40px !important;
  font-size: 1rem;
  font-weight: normal;
  border-radius: 0;
  letter-spacing: -0.48px;
  transition: 0.3s;
  cursor: pointer;
  text-transform: uppercase;
  &.sm-btn {
    min-height: 30px !important;
    padding: 0 10px;
    font-size: 12px;
  }
  &.md-btn {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.15px;
  }
  &.rounded {
    border-radius: 16px !important;
  }
  &.lg-btn {
    min-height: 60px !important;
    font-size: 18px;
    font-weight: 500;
    padding: 0 20px !important;
  }
  @include mq('phablet') {
    padding: 0 14px;
  }
}

$btn-list: (
  (primary, $black, $white, false, true),
  (secondary, $secondary, $white, false, true),
  (grayscale, $third-color, $white, true, true),
  (facebook, $facebook, $white, false, false)
);

@each $btn-name, $bg-color, $color, $has-hover, $has-outline in $btn-list {
  .#{$btn-name}-fill {
    @extend %btn-style;
    background-color: $bg-color;
    color: $color;
    @if $has-hover {
      &:hover {
        background-color: $color;
        color: $bg-color;
        border: 1px solid $bg-color;
      }
    } @else {
      &:hover,
      &:focus {
        background-color: $bg-color;
        color: $color;
      }
    }
  }
  @if $has-outline {
    .#{$btn-name}-outline {
      @extend %btn-style;
      background-color: #fff;
      color: $bg-color;
      border: 1px solid $bg-color;
      &:hover {
        color: #fff;
        background-color: $bg-color;
      }
    }
  }
}
.ant-btn {
  border-radius: 10px !important;
}
.ant-btn-lg {
  // border-radius: 10px;
  // height: 56px;
  // min-width: 229px;
  // font-size: 18px;
  // font-weight: 600;
  border: 0 !important;
  min-width: 135px;
  width: 100%;
  border-radius: 18px !important;
  height: 40px;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 992px) {
  .ant-btn.ant-btn-lg {
    // height: 50px;
    font-size: 16px;
  }
}
