//************************** COLORS **************************//

//primary color
// $primary:#0056b8;
$primary: #242729;
$primary-2: #002575;
$secondary: #d60812;
$third-color: #bebebe;
$fourth_color: #f86659;
$color-main: #00672C;
$facebook: #3b5998;

/*other colors*/
$white: #fff;
$black: #000000;
$validation: #d50000;

// start range slider var
$slider-line-height: 20px !default;
$slider-border-radius: 4px !default;

//************************** FONTS **************************//

// @font-face {
//     font-family: proxima;
//     src: url(../fonts/proxima/ProximaNova-Regular.ttf);
// }

// @font-face {
//     font-family: proxima;
//     // src: url(../fonts/proxima/ProximaNova-Regular.otf);
//         src: url(../fonts/proxima/ProximaNova-Regular.ttf);
// }

// @font-face {
//     font-family: proxima;
//     font-weight: 500;
//     src: url(../fonts/proxima/ProximaNova-Sbold.otf);
// }

// @font-face {
//     font-family: proxima;
//     font-weight: bold;
//     src: url(../fonts/proxima/ProximaNova-Bold.otf);
// }

// // @font-face {
// //     font-family: gotham;
// //     font-weight: 500;
// //     src: url(../fonts/gotham/Gotham-Medium.otf);
// // }

// @font-face {
//     font-family: proxima;
//     font-weight: 900;
//     src: url(../fonts/proxima/ProximaNova-Extrabold.otf);
// }

// @font-face {
//     font-family: "cairo";
//     src: url(../fonts/Cairo/Cairo-Regular.ttf);
// }

// @font-face {
//     font-family: "cairo";
//     font-weight: 700;
//     src: url(../fonts/Cairo/Cairo-Bold.ttf);
// }