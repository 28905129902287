
.cart-products-wrapper{
    .cart-products-content{
        // padding: 40px 28.5px 20px;
        border-radius: 5px;
        // box-shadow: 0 10px 20px 0 #ebf2f9;
        background-color: #ffffff;
    }
    .cart-products--header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 36px;
        h4{
            font-size: 14px;
            font-weight: normal;
            color: #ababab; 
        }
    }
    .cart-products--list{
        > .ant-skeleton{
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: solid 1px #dbdbdb;
            margin-bottom: 20px;
            padding: 35px 38px 30px 25px;
        }
    }
}
.empty-cart-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 20px; 
    h4{
        font-size: 16px;
        font-weight: 600;
        line-height: 1.6;
        color: #000000;
        margin-right: 14px;
        margin: 20px 0;
    }
}