.favorite-tab-wrapper{
  height: 100%;
  .no-favorite-holder{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    p{
      font-size: 14px;
      color: #000000;
      line-height: 2.29;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    // .anticon{
    //   svg{
    //     font-size: 30px;
    //     font-size: 54px;
    //     fill: #ababab;
    //   }
    // }
    // .ant-btn{
    //   height: 47px;
    //   font-size: 16px;
    //   font-weight: 500;
    // }
  }
  .favorite-list-title{
    font-size: 15px;
    font-weight:500;
    margin-bottom: 25px;
  }
}

.favorite-list-content{
  .cart-product-card{
    border: 0;
    border-bottom: solid 1px #b8b8b8; 
    border-radius: 0;
    margin-bottom: 0;
    padding-top: 15px;
    &:last-of-type{ 
      border-bottom: 0; 
    }
  }
  .product-img{
    border-radius: 12px; 
    border: solid 1px #e9ffde;
    padding: 5px;
  }
}