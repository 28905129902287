.about-page-wrapper {
  background-color: #fff;

  .about-hero {
    height: 380px;
    background-image: url('https://metro-markets.com/plugins/user/images/aboutImage.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    &:lang(ar) {
      // background-image: url('../../assets/img/Recipie-ar.jpeg');
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-size: 44px;
      font-weight: 600;
      line-height: 1.55;
    }

    @media only screen and (max-width: 992px) {
      height: 180px;

      h1 {
        font-size: 34px;
      }
    }
  }

  .ant-breadcrumb {
    margin-top: 45px;
    margin-bottom: 30px;
  }

  .about-content {
    width: 85%;
    margin: auto;

    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }

  .about-grid-wrapper {
    // p{
    //     font-size: 16px;
    //     line-height: 1.5;
    //     margin-bottom: 30px;
    //     width: 80%;
    //     @media only screen and (max-width: 992px) {
    //         width: 100%;
    //         margin-bottom: 30px;
    //       }
    // }
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 80px;

    .sec {
      padding: 10px 0;

      p {
        font-size: 17px;
        line-height: 1.5;
        color: #465a69;
        margin-bottom: 15px;
        max-inline-size: 80ch;


        span {
          font-weight: 500;
          color: #1c2b33;
        }
      }

      &.center {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .title {
          font-size: 16px;
          font-weight: 300;
          color: #465a69;
          text-align: center;
        }

        .desc {
          font-size: 48px;
          font-weight: 600;
          color: #1c2b33;
          text-align: center;
          max-inline-size: 60ch;

          @media only screen and (max-width: 992px) {
            font-size: 28px;

          }
        }
      }
    }
  }
}