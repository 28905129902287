@import '../../scss/slider.scss';
@import '../../scss/mixins';

.slider-section-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-section-content {
  min-height: 300px;
  margin-top: 20px;

  @include mq('desktop-wide') {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .slick-track {
    display: flex;
  }

  .slick-next {
    right: -90px;
  }

  .slick-prev {
    left: -90px;
  }

  .slick-next::before,
  .slick-prev::before {
    content: '';
    background-repeat: no-repeat;
    background-position: 50%;
    display: block;
    width: 30px;
    height: 30px;
    background: #00672C;
    opacity: 1;
    border-radius: 50%;
    position: absolute;
  }

  .slick-disabled {
    &::before {
      background: #e4e4e4;
    }

    svg {
      fill: #8a8a8a;
    }
  }

  .slick-button-icon {
    width: 12px;
    z-index: 1;
    fill: #fff;
  }

  .slick-slide {
    height: inherit;
    // box-shadow: 0 20px 40px 0 rgb(116 116 116 / 16%);
    margin-top: 50px;
    margin-bottom: 50px;

    >div {
      height: 100%;
    }
  }

  .slick-slide .product-card-wrapper {
    margin: 0 13px;
    height: 100%;

    // box-shadow: none;
    .product-name {
      min-height: 30px;
    }

    .price-holder {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .slick-list {
    margin: 0 -26px;
  }

  .product-img {
    width: auto;
  }

  .slide-holder {
    height: 100%;
  }

  .ant-skeleton {
    display: table;
    width: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    max-width: 320px;
    box-shadow: 0 20px 40px 0 #74747429;
    padding: 25px;
    margin: 0 10px;
    padding-bottom: 50px;

    .ant-skeleton-header {
      margin-bottom: 24px;
    }

    @media only screen and (max-width: 992px) {
      max-width: initial;
    }
  }
}